import { configureStore } from "@reduxjs/toolkit";
import agentsSlice from "./agents/agentsSlice";
import organizationsSlice from "./organizations/organizationsSlice";
import storesSlice from "./stores/storesSlice";
import tableSlice from "./table/tableSlice";

export const store = configureStore({
  reducer: {
    table: tableSlice,
    agents: agentsSlice,
    organizations: organizationsSlice,
    stores: storesSlice,
  },
});
