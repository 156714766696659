export const readXmlFileAsText = async (file) => {
  const reader = new FileReader(file);
  reader.readAsText(file, "CP1251");

  let readFile = (reader) => {
    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const result = await readFile(reader);
  return result;
};
