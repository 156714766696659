import axios from "axios";
import asyncFilter from "node-filter-async";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { changeMatchName } from "../../model/store/table/tableSlice";

export const ProductSelect = ({ name, matchedProduct, isFirstTime }) => {
  const [value, setValue] = useState(null);
  const [isCleared, setCleared] = useState(false);
  console.log("matched", matchedProduct);
  useEffect(() => {
    (async () => {
      if (matchedProduct?.name) {
        return;
      }
      if (isCleared) {
        return;
      }
      const auth = await getItemFromLocalStorage("auth");
      const res = await axios.post(`${process.env.REACT_APP_HOST}/find-in-db`, {
        productName: name,
        login: auth.login,
      });
      if (res.data) {
        dispatch(changeMatchName({ name, value: { name: res.data } }));
        setValue({
          name: res.data,
        });
      }
    })();
  }, [matchedProduct, name]);
  useEffect(() => {
    if (matchedProduct?.name) {
      setValue(matchedProduct);
    }
    console.log(matchedProduct);
  }, [matchedProduct]);

  const dispatch = useDispatch();

  const loadOptions = async (value) => {
    const products = await getItemFromLocalStorage("assortment");

    const wordsFromInput = value.split(" ");
    const res = await asyncFilter(products, (currentValue) => {
      const wordsFromProduct = currentValue.name.split(" ");
      return wordsFromInput.every((wordFromInput) => {
        return wordsFromProduct.some((wordFromProduct) =>
          wordFromProduct.toLowerCase().includes(wordFromInput.toLowerCase())
        );
      });
    });
    return res;
  };

  const onChange = (value, trigger) => {
    if (trigger.action === "clear") {
      setCleared(true);
      dispatch(changeMatchName({ name, value: undefined }));
      setValue(null);
      return;
    }
    dispatch(changeMatchName({ name, value }));
  };
  const handleInputChange = (e) => {
    setValue(e.value);
  };

  return (
    <AsyncSelect
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.name}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder="Начните вводить"
      isClearable
      onInputChange={handleInputChange}
      value={value}
    />
  );
};
