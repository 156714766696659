import { Layout } from "@consta/uikit/LayoutCanary";
import { Text } from "@consta/uikit/Text";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { chooseStore } from "../../model/store/stores/storesSlice";
import { getCustomStyles } from "./getCustomStyles";

export const StoreSelect = ({ error }) => {
  const [stores, setStores] = useState([]);
  const dispatch = useDispatch();
  const [isError, setError] = useState(false);

  useEffect(() => {
    const getStores = async () => {
      const storesFromLocalStorage = await getItemFromLocalStorage("stores");
      setStores(storesFromLocalStorage);
    };
    getStores();
  }, []);

  useEffect(() => {
    if (error === "store") {
      setError(true);
    }
  }, [error]);

  const onChange = (value) => {
    dispatch(chooseStore(value));
    setError(false);
  };

  const styles = getCustomStyles(isError);
  return (
    <Layout direction="column">
      <Text>Склад</Text>
      <Select
        styles={styles}
        options={stores}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        onChange={onChange}
      />
    </Layout>
  );
};
