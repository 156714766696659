import { getItemFromLocalStorage } from "../../api/localStorage/getItemFromLocalStorage";

export const getAuth = async () => {
   try {
      const authItem = await getItemFromLocalStorage("auth");

      const { login, password } = authItem;

      return { login, password };
   } catch (error) {
      return null;
   }
};
