import { Layout } from "@consta/uikit/LayoutCanary";
import { Text } from "@consta/uikit/Text";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { getAuth } from "../../model/connectors/storage/getAuth";
import { chooseAgent } from "../../model/store/agents/agentsSlice";
import { getCustomStyles } from "./getCustomStyles";
export const AgentSelect = ({ error }) => {
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState(null);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const getAgentFromLocalStorage = async () => {
      const agent = await getItemFromLocalStorage("agent");
      if (!agent) {
        return;
      }
      setDefaultValue(agent);
    };
    getAgentFromLocalStorage();
  }, []);

  useEffect(() => {
    if (error === "agent") {
      setError(true);
    }
  }, [error]);

  const onChange = (value) => {
    dispatch(chooseAgent(value));
    setError(false);
  };
  const loadOptions = (inputValue) => {
    if (!inputValue.replaceAll(".", "")) {
      return;
    }
    return getAuth()
      .then(({ login, password }) =>
        axios.post(`${process.env.REACT_APP_HOST}/get-agent/${inputValue}`, {
          login,
          password,
        })
      )
      .then((res) => {
        return res.data;
      });
  };

  // const onClick = () => {
  //   setError(false);
  // };

  const styles = getCustomStyles(isError);
  return (
    <Layout direction="column">
      <Text>Контрагент</Text>
      <AsyncSelect
        // onClick={onClick}
        styles={styles}
        defaultInputValue={defaultValue}
        cacheOptions
        defaultOptions
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder="Начните вводить"
      />
    </Layout>
  );
};
