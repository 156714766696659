import { createSlice, current } from "@reduxjs/toolkit";
import { getItemFromLocalStorage } from "../../api/localStorage/getItemFromLocalStorage";

const initialState = {
  sheetRows: [],
  matchedProducts: [],
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setSheetRows: (state, { payload }) => {
      const { rows, assortment } = payload;

      const rowsWithEqual = rows.map((row) => {
        const [name, quantity, price] = row;

        const matchedProduct = assortment.find(
          (product) => product.name.toLowerCase() === name.toLowerCase()
        );

        // if (matchedProduct) {
        //   state.matchedProducts.push({
        //     name,
        //     quantity,
        //     price,
        //   });
        // }

        return {
          name,
          quantity,
          price,
          matchedProduct: matchedProduct?.name,
          isFirstTime: true,
        };
      });

      state.sheetRows = rowsWithEqual;
    },
    changeMatchName: (state, { payload }) => {
      const { sheetRows } = state;
      const { name, value: matchedProduct } = payload;

      // const matchedProduct = state.assortmentFromMs.find(
      //   (product) => product.name === name
      // );

      const sheetRowIndex = sheetRows.findIndex((row) => row.name === name);
      sheetRows[sheetRowIndex].matchedProduct = matchedProduct;
      sheetRows[sheetRowIndex].isFirstTime = false;
    },
    changePrice: (state, { payload }) => {
      const { sheetRows } = state;
      const { name, value } = payload;

      const currentProduct = sheetRows.findIndex(
        (product) => product.name === name
      );
      sheetRows[currentProduct].price = +value;
    },
    changeQuantity: (state, { payload }) => {
      const { sheetRows } = state;
      const { name, value } = payload;

      const currentProduct = sheetRows.findIndex(
        (product) => product.name === name
      );
      sheetRows[currentProduct].quantity = +value;
    },
  },
});

export const { setSheetRows, changeMatchName, changePrice, changeQuantity } =
  tableSlice.actions;

export default tableSlice.reducer;
