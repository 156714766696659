import { Button } from "@consta/uikit/Button";
import { TextField } from "@consta/uikit/TextField";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItemsFromMs } from "../../model/api/db/getItemsFromMs";
import { setItemToLocalStorage } from "../../model/api/localStorage/setItemToLocalStorage";
import { getAuth } from "../../model/connectors/storage/getAuth";
import { saveAuth } from "../../model/connectors/storage/saveAuth";
import { setOrganizations } from "../../model/store/organizations/organizationsSlice";
import { setStores } from "../../model/store/stores/storesSlice";
import "./LoginPage.css";
export const LoginPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageLoadingText, setPageLoadingText] = useState("");

  const onClickLoginButton = async (e) => {
    const isFieldsFulfilled = login && password;
    const isFieldsEqualString = typeof (login + password) === "string";

    if (isFieldsFulfilled && isFieldsEqualString) {
      try {
        setError(null);
        setIsPageLoading(true);
        setPageLoadingText("Подождите");

        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/login/`,
          {
            login,
            password,
          }
        );

        if (response.status === 200) {
          const previousAuth = await getAuth();
          const previousLogin = previousAuth?.login;

          const isSameAccount = previousLogin === login;
          const auth = await saveAuth(login, password);

          if (!isSameAccount) {
            setPageLoadingText(
              "Загружаю все товары, это может занять до пяти минут."
            );
            const assortment = await getItemsFromMs("entity/assortment", auth);
            await setItemToLocalStorage("assortment", assortment);

            setPageLoadingText("Загружаю организации");

            const organizations = await getItemsFromMs(
              "entity/organization",
              auth
            );

            await setItemToLocalStorage("organizations", organizations);
            dispatch(setOrganizations(organizations));

            const stores = await getItemsFromMs("entity/store", auth);
            await setItemToLocalStorage("stores", stores);
            dispatch(setStores(stores));

            // const organizations = await getItemsFromMs('entity/organization', auth)

            setIsPageLoading(false);
          }

          navigate("/table");
        } else {
          setIsPageLoading(false);
          setError("Неверный логин или пароль");
        }
      } catch (error) {
        setIsPageLoading(false);
        setError("Что-то пошло не так");
      }
    }
  };

  const onChangeLogin = (e) => {
    setLogin(e.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.value);
  };

  return (
    <div className="login-page">
      <div className="login__wrapper">
        <div className="login__group" style={{ paddingTop: "20%" }}>
          <TextField
            type="text"
            label="Логин"
            placeholder="admin@romashka"
            size="l"
            value={login}
            onChange={onChangeLogin}
            required
          />
          <TextField
            type="password"
            label="Пароль"
            placeholder="Пароль"
            value={password}
            onChange={onChangePassword}
            size="l"
            required
          />
          <Button
            label="Войти"
            size="l"
            onClick={onClickLoginButton}
            loading={isPageLoading}
          />
          {isPageLoading && (
            <h4 style={{ textAlign: "center" }}>{pageLoadingText}</h4>
          )}
          {error && <h4>{error}</h4>}
        </div>
      </div>
    </div>
  );
};
