import axios from "axios";
export const getItemsFromMs = async (path, { login, password }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_HOST}/get-items?path=${path}`,
      {
        login,
        password,
      }
    );
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};
