import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  choosedStore: null,
};

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    setStores: (state, { payload: stores }) => {
      state.list = stores;
    },
    chooseStore: (state, { payload: store }) => {
      state.choosedStore = store;
    },
  },
});

export const { setStores, chooseStore } = storesSlice.actions;

export default storesSlice.reducer;
