import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  choosedAgent: null,
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    chooseAgent: (state, { payload: agent }) => {
      state.choosedAgent = agent;
    },
    clearAgent: (state) => {
      state.choosedAgent = null;
    },
  },
});

export const { chooseAgent, clearAgent } = agentsSlice.actions;

export default agentsSlice.reducer;
