export const getCustomStyles = (isError) => {
  if (isError) {
    return {
      control: (provided) => ({
        ...provided,
        border: "1px solid red",
      }),
    };
  }
};
