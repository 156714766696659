export const readFile = async (file) => {
  const reader = new FileReader(file);
  reader.readAsArrayBuffer(file);

  let readFile = (reader) => {
    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  const result = await readFile(reader);
  return result;
};
