import "./App.css";
import { Routes, Route } from "react-router-dom";

import { LoginPage } from "./pages/LoginPage/LoginPage";
import { Drag } from "./pages/DragAndDrop/Drag";
import { CustomTable } from "./pages/CustomTable/CustomTable";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/compare" element={<CustomTable />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/table" element={<Drag />} />
      </Routes>
    </div>
  );
}

export default App;
