import React from "react";
import { useDispatch } from "react-redux";
import {
  changeMatchName,
  changePrice,
  changeQuantity,
} from "../../model/store/table/tableSlice";
import { CustomNumberInput } from "../CustomNumberInput/CustomNumberInput";
import { ProductSelect } from "../Selects/ProductSelect";

export const CustomRow = ({ row }) => {
  const dispatch = useDispatch();
  const { name, quantity, price, matchedProduct, isFirstTime } = row;

  const handleChangeQuantity = (value) => {
    dispatch(changeQuantity({ name, value }));
  };

  const handleChangePrice = (value) => {
    dispatch(changePrice({ name, value }));
  };

  const handleChangeMatchName = (value) => {
    dispatch(changeMatchName({ name, value }));
  };

  return (
    <tr>
      <td>{name}</td>
      <td>
        {
          <ProductSelect
            name={name}
            matchedProduct={matchedProduct}
            isFirstTime={isFirstTime}
          />
        }
      </td>
      <td style={{ textAlign: "center" }}>
        <CustomNumberInput
          value={quantity}
          handleChange={handleChangeQuantity}
          placeholder="Цена"
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <CustomNumberInput
          value={price}
          handleChange={handleChangePrice}
          placeholder="Количество"
        />
      </td>
    </tr>
  );
};
