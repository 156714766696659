import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { CustomRow } from "../CustomTable/CustomRow";

export const Rows = () => {
  const rows = useSelector((state) => state.table.sheetRows);
  const navigate = useNavigate();
  useEffect(() => {
    // const getRowsFromLocalStorage =async () => {
    //   const rowsFromLocalStorage = getItemFromLocalStorage('rows');
    //   return rowsFromLocalStorage;
    // }

    if (!rows.length) {
      navigate("/table");
    }
  }, [rows]);

  const rowElements = rows.map((row, id) => <CustomRow row={row} key={id} />);
  return <tbody>{rowElements}</tbody>;
};
