import localforage from "localforage";

export const getItemFromLocalStorage = async (key) => {
  try {
    const item = await localforage.getItem(key);
    return item;
  } catch (error) {
    throw new Error(error);
  }
};
