import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DragNDropField } from "@consta/uikit/DragNDropField";
import { Text } from "@consta/uikit/Text";
import { readNewSheet } from "../../model/api/excel/readNewSheet";
import { readNewXml } from "../../model/api/xml/readNewSheet";
import { setSheetRows } from "../../model/store/table/tableSlice";
import { useNavigate } from "react-router-dom";
import { getAuth } from "../../model/connectors/storage/getAuth";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { useState } from "react";
import { Informer } from "@consta/uikit/Informer";

export const Drag = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const isUserAuth = async () => {
      const auth = await getAuth();
      if (!auth?.login) {
        navigate("/");
      }
    };
    isUserAuth();
  }, []);

  const getRows = async (files) => {
    try {
      const firstFile = files[0];
      //  console.log(firstFile);
      if (firstFile.type === "text/xml") {
        const rows = await readNewXml(firstFile);
        //    console.log(rows);
        const assortment = await getItemFromLocalStorage("assortment");
        dispatch(setSheetRows({ rows, assortment }));
      } else {
        const rows = await readNewSheet(firstFile);
        //     console.log(rows);
        const assortment = await getItemFromLocalStorage("assortment");
        dispatch(setSheetRows({ rows, assortment }));
      }

      navigate("/compare");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <DragNDropField accept={[".xlsx", ".xls", ".xml"]} onDropFiles={getRows}>
        <Text>
          Перетащите сюда Excel таблицу c заказом(.xlsx, .xls), или файл приемки
          из ЭДО.
        </Text>
      </DragNDropField>
      {error ? (
        <Informer
          status="alert"
          view="filled"
          title={error}
          // label="Подходит, чтобы рассказать об ошибке в процессе"
        />
      ) : (
        ""
      )}
    </>
  );
};
