import convert from "xml-js";
import { readXmlFileAsText } from "../fs/readXmlFileAsText";

export const readNewXml = async (file) => {
  const fileText = await readXmlFileAsText(file);
  const json = JSON.parse(
    convert.xml2json(fileText, { compact: false, spaces: 4 })
  );
  const elements = json.elements[0].elements[1].elements[1].elements;
  elements.pop();
  //console.log("XML");
  const xmlrows = elements.map((item) => {
    let row = [];
    row.push(item.attributes.НаимТов);
    row.push(Number(item.attributes.КолТов));
    row.push(Number(item.attributes.ЦенаТов));
    return row;
  });
  // console.log(xmlrows);
  //console.log(elements);

  let rows = [];
  for (let row of xmlrows) {
    if (!Number(row[1])) {
      throw new Error("Во втором стобце должны быть только числа");
    }
    if (!Number(row[2])) {
      throw new Error("В третьем столбце должны быть только числа" + row[3]);
    }
    const existCells = row.filter((value) => value);
    rows.push(existCells);
  }
  return rows;
};
