import { Button } from "@consta/uikit/Button";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAuth } from "../../model/connectors/storage/getAuth";
import { CSVLink, CSVDownload } from "react-csv";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage.js";

export const SendDataButton = ({
  handleIsDataSent,
  handleError,
  handleMainError,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const choosedAgent = useSelector((state) => state.agents.choosedAgent);
  const choosedOrganization = useSelector(
    (state) => state.organizations.choosedOrganization
  );
  const choosedStore = useSelector((store) => store.stores.choosedStore);
  const allProducts = useSelector((state) => state.table.sheetRows);

  const onClick = async () => {
    try {
      if (!choosedAgent) {
        throw new Error("agent");
      }
      if (!choosedStore) {
        throw new Error("store");
      }
      if (!choosedOrganization) {
        throw new Error("organization");
      }

      setIsButtonLoading(true);
      const agent = {
        meta: choosedAgent.meta,
      };
      const organization = {
        meta: choosedOrganization.meta,
      };
      const store = {
        meta: choosedStore.meta,
      };
      console.log("Все товары", allProducts);

      const positionsWithoutMatched = allProducts.filter(
        (position) => !position.matchedProduct
      );
      const positionsWithMatched = allProducts.filter(
        (position) => position.matchedProduct
      );
      console.log("Не соотнесенные: ", positionsWithoutMatched);
      console.log("Cоотнесенные: ", positionsWithMatched);
      const { login, password } = await getAuth();
      /*
      const { data: createdProducts } = await axios.post(
        `${process.env.REACT_APP_HOST}/create-products`,
        {
          login,
          password,
          positions: positionsWithoutMatched,
        }
      );
      console.log(createdProducts);
*/
      // const fullProducts = [...positionsWithMatched, ...createdProducts];

      const productsWithMeta = await getItemFromLocalStorage("assortment");
      const positions = positionsWithMatched.map((product) => {
        console.log(product.name);
        const productWithMeta = productsWithMeta.find(
          (productWithMeta) =>
            productWithMeta.name === product.matchedProduct.name
        );

        return {
          quantity: product.quantity,
          price: product.price * 100,
          name: product.matchedProduct.name,
          previousProduct: product.name,
          meta: productWithMeta?.meta,
        };
      });

      // const { data: createdProducts } = await axios.post(
      //   `${process.env.REACT_APP_HOST}/create-products`,
      //   {
      //     login,
      //     password,
      //     positions: positionsWithoutMatched,
      //   }
      // );
      // console.log(createdProducts, "createdProducts");

      // const positionsWithCreated = allProducts.map((position) => {
      //   for (const { name, meta } of createdProducts) {
      //     if (position.name === name) {
      //       position.matchedProduct = {
      //         meta,
      //       };
      //     }
      //     return position;
      //   }
      // });

      console.log("positions to send", positions);

      const createdPurchase = await axios.post(
        `${process.env.REACT_APP_HOST}/create-purchase?path=entity/purchaseorder`,
        {
          login,
          password,
          positions,
          agent,
          organization,
          store,
        }
      );
      console.log(createdPurchase);

      setIsButtonLoading(false);
      handleIsDataSent(true);
      //
    } catch (error) {
      console.error(error);
      setIsButtonLoading(false);

      const moyskladError = error?.response?.data?.errors[0]?.error;
      if (moyskladError) {
        handleMainError(moyskladError);
        return;
      }
      handleError(error);
      return;
    }
  };

  const pd2 = allProducts.filter((position) => !position.matchedProduct);
  const pd3 = pd2.map((product) => ({
    Наименование: product.name,
    Количество: product.quantity,
    Цена: product.price,
  }));
  return (
    <>
      <Button
        label="Создать заказ"
        onClick={onClick}
        loading={isButtonLoading}
      />
      <CSVLink
        data={pd3}
        separator={";"}
        className="Button Button_size_m Button_view_primary Button_width_default Button_form_default MixFocus"
      >
        Скачать остальное
      </CSVLink>
    </>
  );
};
