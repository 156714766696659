import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@consta/uikit/Button";
import { Modal } from "@consta/uikit/Modal";
import { OrganizationSelect } from "../../components/Selects/OrganizationSelect";
import { StoreSelect } from "../../components/Selects/StoreSelect";
import { AgentSelect } from "../../components/Selects/AgentSelect";
import { Rows } from "../../components/Rows/Rows";
import { useNavigate } from "react-router-dom";
import { getAuth } from "../../model/connectors/storage/getAuth";
import { SendDataButton } from "../../components/SendDataButton/SendDataButton";
import { Informer } from "@consta/uikit/Informer";
import { Layout } from "@consta/uikit/LayoutCanary";
import { IconBackward } from "@consta/uikit/IconBackward";
import { useDispatch } from "react-redux";
import { getItemsFromMs } from "../../model/api/db/getItemsFromMs";
import { setItemToLocalStorage } from "../../model/api/localStorage/setItemToLocalStorage";
import { clearAgent } from "../../model/store/agents/agentsSlice";
import { clearOrganization } from "../../model/store/organizations/organizationsSlice";
import localforage from "localforage";

export const CustomTable = () => {
  const dispatch = useDispatch();
  const [isShowDone, setIsShowDone] = useState(null);
  const [isShowUpdate, setIsShowUpdate] = useState(null);
  const [error, setError] = useState(null);
  const [mainError, setMainError] = useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageLoadingText, setPageLoadingText] = useState("");
  const [isButtonHidden, hideButton] = useState(false);
  useEffect(() => {
    const isUserAuth = async () => {
      const auth = await getAuth();
      if (!auth?.login) {
        navigate("/");
      }
    };
    isUserAuth();
  }, []);

  const handleIsDataSent = (isSent) => {
    if (isSent) {
      setIsShowDone(true);
      setTimeout(() => {
        setIsShowDone(false);
      }, 3000);
    }
  };

  console.log("rerender all table");

  const handleError = (message) => {
    setError(message);
  };

  const handleMainError = (message) => {
    setMainError(message);
    setTimeout(() => {
      setMainError(null);
    }, 5000);
  };

  const onClickBack = () => {
    navigate("/table");
  };

  const onClickLogout = () => {
    dispatch(clearAgent());
    dispatch(clearOrganization());
    localforage.clear();
    navigate("/");
  };
  const onShowUpdateProducts = () => {
    setIsModalOpen(true);
  };

  const onUpdateProducts = async () => {
    hideButton(true);
    const auth = await localforage.getItem("auth", function (err, value) {
      return value;
    });
    console.log(auth.login);
    const isFieldsFulfilled = auth.login && auth.password;
    const isFieldsEqualString =
      typeof (auth.login + auth.password) === "string";

    if (isFieldsFulfilled && isFieldsEqualString) {
      try {
        setError(null);
        setIsPageLoading(true);
        setPageLoadingText("Подождите");

        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/login/`,
          auth
        );

        if (response.status === 200) {
          const isSameAccount = auth?.login;

          setPageLoadingText(
            "Загружаю все товары, это может занять до пяти минут."
          );
          const assortment = await getItemsFromMs("entity/assortment", auth);
          await setItemToLocalStorage("assortment", assortment);
          setIsPageLoading(false);
          hideButton(false);
          setIsModalOpen(false);
          setIsShowUpdate(true);
          setTimeout(() => {
            setIsShowUpdate(false);
          }, 3000);
        } else {
          setIsPageLoading(false);
          hideButton(false);
          setError("Неверный логин или пароль");
        }
      } catch (error) {
        hideButton(false);
        setIsPageLoading(false);
        setError("Что-то пошло не так");
      }
    }
  };
  return (
    <>
      {isShowDone && (
        <Informer
          style={{ marginBottom: "15px" }}
          status="success"
          view="filled"
          title="Данные успешно отправлены"
          // label="Подходит, чтобы рассказать об успешном результате процесса, в котором сейчас находится пользователь"
        />
      )}
      {mainError && (
        <Informer
          style={{ marginBottom: "15px" }}
          status="alert"
          view="filled"
          title="Ошибка загрузки данных"
          label={mainError}
          // label="Подходит, чтобы рассказать об успешном результате процесса, в котором сейчас находится пользователь"
        />
      )}
      {isShowUpdate && (
        <Informer
          style={{ marginBottom: "15px" }}
          status="success"
          view="filled"
          title="Товары успешно загружены"
          // label="Подходит, чтобы рассказать об успешном результате процесса, в котором сейчас находится пользователь"
        />
      )}
      <Layout
        style={{ marginBottom: "20px", display: "flex", columnGap: "15px" }}
      >
        <Layout
          flex={1}
          style={{ alignItems: "end", display: "flex", gap: "10px" }}
        >
          <Button onClick={onClickBack} iconLeft={IconBackward} />
          <SendDataButton
            handleIsDataSent={handleIsDataSent}
            handleError={handleError}
            handleMainError={handleMainError}
          />
        </Layout>
        <Layout style={{ display: "flex", gap: "25px" }} flex={2}>
          <AgentSelect error={error?.message} />
          <StoreSelect error={error?.message} />
          <OrganizationSelect error={error?.message} />
        </Layout>
        <Layout style={{ alignItems: "end" }}>
          <Button label="Синхрон" onClick={onShowUpdateProducts} view="ghost" />
        </Layout>
        <Layout style={{ alignItems: "end" }}>
          <Button label="Выйти" onClick={onClickLogout} view="ghost" />
        </Layout>
      </Layout>
      <Table>
        <thead>
          <tr>
            <th>Название</th>
            <th style={{ textAlign: "center" }}>Название из Мой Склад</th>
            <th style={{ textAlign: "center" }}>Количество</th>
            <th style={{ textAlign: "center" }}>Цена</th>
          </tr>
        </thead>
        <Rows />
      </Table>
      <Modal
        style={{ width: "50%" }}
        isOpen={isModalOpen}
        hasOverlay
        // onClickOutside={() => setIsModalOpen(false)}
        onEsc={() => setIsModalOpen(false)}
      >
        <div className="login__wrapper">
          <div
            className="login__group"
            style={{ paddingTop: "20%", paddingBottom: "20%" }}
          >
            <h4 style={{ textAlign: "center" }}>
              Для обновления товарной базы нажмите кнопку "Загрузить товары"
            </h4>
            {isPageLoading && (
              <h4 style={{ textAlign: "center" }}>{pageLoadingText}</h4>
            )}
            {error && <h4>{error}</h4>}
            <Button
              style={{ display: isButtonHidden ? "none" : "inline" }}
              size="m"
              view="primary"
              label="Загрузить товары"
              width="default"
              onClick={() => onUpdateProducts()}
            />
            <Button
              size="m"
              view="primary"
              label="Закрыть"
              width="default"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
        </div>
        <div></div>
      </Modal>
    </>
  );
};
