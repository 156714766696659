import ExcelJS from "exceljs";
import { readFile } from "../fs/readFile";

export const readNewSheet = async (file) => {
  const workbook = new ExcelJS.Workbook();
  const fileAsArray = await readFile(file);

  await workbook.xlsx.load(fileAsArray);
  const worksheet = workbook.worksheets[0];

  let rows = [];
  worksheet.eachRow((row, rowCount) => {
    if (rowCount === 1) {
      return;
    }
    if (!Number(row.values[2])) {
      throw new Error("Во втором стобце должны быть только числа");
    }
    if (!Number(row.values[3])) {
      throw new Error("В третьем столбце должны быть только числа");
    }
    const existCells = row.values.filter((value) => value);
    rows.push(existCells);
  });
  return rows;
};
