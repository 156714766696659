import { setItemToLocalStorage } from "../../api/localStorage/setItemToLocalStorage";

export const saveAuth = async (login, password) => {
  const auth = {
    login,
    password,
  };
  setItemToLocalStorage("auth", auth).catch((error) => {
    throw new Error("Can't save auth data", error);
  });
  return auth;
};
