import React from "react";
import { TextField } from "@consta/uikit/TextField";

export const CustomNumberInput = ({ value, handleChange, placeholder }) => {
  const onChange = (e) => {
    if (e.value === null) {
      handleChange(1);
    }
    if (!(Number(e.value) && isFinite(e.value))) {
      return;
    }

    handleChange(e.value.trim());
  };
  const onClick = (e) => {
    e.target.select();
  };
  return (
    <TextField
      style={{ width: 60, textAlign: "center" }}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onClick={onClick}
    />
  );
};
