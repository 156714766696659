import { Layout } from "@consta/uikit/LayoutCanary";
import { Text } from "@consta/uikit/Text";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select";
import { getItemFromLocalStorage } from "../../model/api/localStorage/getItemFromLocalStorage";
import { chooseOrganization } from "../../model/store/organizations/organizationsSlice";
import { getCustomStyles } from "./getCustomStyles";

export const OrganizationSelect = ({ error }) => {
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const getOrganizations = async () => {
      const organizationsFromLocalStorage = await getItemFromLocalStorage(
        "organizations"
      );
      setOrganizations(organizationsFromLocalStorage);
    };
    getOrganizations();
  }, []);

  useEffect(() => {
    if (error === "organization") {
      setError(true);
    }
  }, [error]);

  const onChange = (value) => {
    dispatch(chooseOrganization(value));
    setError(false);
  };

  const customStyles = getCustomStyles(isError);
  return (
    <Layout direction="column">
      <Text>Организация</Text>
      <AsyncSelect
        styles={customStyles}
        options={organizations}
        onChange={onChange}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.name}
      />
    </Layout>
  );
};
