import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  choosedOrganization: null,
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, { payload: organizations }) => {
      state.list = organizations;
    },
    chooseOrganization: (state, { payload: organization }) => {
      state.choosedOrganization = organization;
    },
    clearOrganization: (state) => {
      state.choosedOrganization = null;
    },
  },
});

export const { setOrganizations, chooseOrganization, clearOrganization } =
  organizationsSlice.actions;

export default organizationsSlice.reducer;
